<template>
	<div class="this-block" v-if="wordCard.word">
		<!-- 音频 -->
		<audio controls ref="commonaudio" class="disnone">
			<source src="" />
		</audio>
		<!-- 音频 -->
		<div class="word-pic"><myImg :src="wordCard.img1"></myImg></div>
		<div class="flex-col">
			<div class="master-left">
				<div class="flex-col">
					<div class="master">
						<span>{{wordCard.word}}</span>
						<div class="box-showdow"></div>
					</div>
				</div>

				<div class="linsten-block">
					<div class="linsten-btn" @click="playAudio(wordCard.phAmMp3)">
						<img src="@/assets/static/linsten.png"/>
					</div>
					<span v-if="wordCard.phAm">美 /{{wordCard.phAm}}/</span>
				</div>
			</div>
		</div>
		<!-- 音标列表 -->
		<div class="say-list" v-if="syllables.length>0">
			<div class="say-item" v-if="syllables[0]" :style="{background: colorlist[0]}">{{syllables[0]}}</div>
			<template v-for="(item,index) in syllables" v-if="index != 0">
				<div :key="index" class="line">-</div>
				<div class="say-item" v-if="index<=5" :style="{background: colorlist[index]}">{{item}}</div>
				<div class="say-item" v-if="index>5" :style="{background: colorlist[0]}">{{item}}</div>
			</template>
		</div>

		<div class="desc-item" v-for="(sitem,index) in wordTpye" :key="index" v-if="sitem.part">
			<span>{{ sitem.part }}</span>
			<span class="desc2" v-for="(mitem, mindex) in sitem.means"
					:key="mindex">{{ mitem }}<span v-html="'&nbsp;'"></span>
			</span>
		</div>

		<div class="other-desc" v-if="exchange.length>0">
			<div class="other-desc-item" v-for="(value, key,index) in exchange" :key="index">
				<span>{{exchangetext[key]}}</span>
				<span class="desc2" v-for="(mitem, mindex) in value"
					:key="mindex">{{ mitem }}<span v-html="'&nbsp;'"></span>
				</span>
			</div>
		</div>

		<!-- 列表栏目 -->
		<div class="filter-list">
			<div class="filter-item" :class="{ 'checked-item': levelChecked === index }" @click="changeLevel(index)"
				v-for="(item, index) in levels" :key="index">
				<span>{{ item }}</span>
				<div class="line" v-if="levelChecked === index"></div>
			</div>
		</div>
		<div class="block10"></div>
		<!-- 例句 -->
		<div v-if="levelChecked === 0">
			<div class="sentence-item" v-for="(item,index) in sentence" :key="index">
				<div v-html="item.name"></div>
				<span>{{ item.cn }}</span>
			</div>
			<!-- 空 -->
			<div class="data_empty_box" style="padding-top: 58px;" v-if="sentence.length<=0">
				<div class="icon"><img class="img100" :src="require('@/assets/static/kong.png')"></img></div>
				<div class="text">暂无数据</div>
			</div>
			<!-- 空 -->
		</div>

		<!-- 近义词 -->
		<div v-if="levelChecked === 1">
			<div class="any-block-style" v-if="synonym.length>0">
				<div class="synonymbox" v-for="(item,index) in synonym" :key="index">
					<div class="text1" v-if="item.means.length>0">{{item.part_name}} {{(item.means[0].word_mean || '')}}</div>
					<div class="text1" v-if="item.means.length<=0">{{item.part_name}}</div>
					<div class="text2box">
						<span class="text2" v-if="item.means.length>0" v-for="(mitem, mindex) in item.means[0].cis"
							:key="mindex">{{ mitem }}
						</span>
					</div>
				</div>
			</div>
			<!-- 空 -->
			<div class="data_empty_box" style="padding-top: 58px;" v-if="synonym.length<=0">
				<div class="icon"><img class="img100" :src="require('@/assets/static/kong.png')"></img></div>
				<div class="text">暂无数据</div>
			</div>
			<!-- 空 -->
		</div>

		<!-- 词组 -->
		<div v-if="levelChecked === 2">
			<div class="sentence-item" v-for="(item,index) in phrase" :key="index" v-if="item.cizu_name">
				<span v-html="item.cizu_name"></span>
				<span v-if="item.jx.length>0">{{item.jx[0].jx_cn_mean || ''}}</span>
			</div>
			<!-- 空 -->
			<div class="data_empty_box" style="padding-top: 58px;" v-if="phrase.length<=0">
				<div class="icon"><img class="img100" :src="require('@/assets/static/kong.png')"></img></div>
				<div class="text fontsize14">暂无数据</div>
			</div>
			<!-- 空 -->
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			wordId: {
				type: Number,
				default: () => {
					return 0;
				}
			},
			wordobj: {
				type: Object,
				default: () => {
					return {};
				}
			},
		},
		watch: {
			wordId: {
				immediate:true,
			    handler(newVal, objVal) {
					if(newVal){
						this.wordCard = {}
						this.uid = newVal
						this.getdetail()
					}
			    },
			},
			wordobj: {
				immediate:true,
			    handler(newVal, objVal) {
					if(newVal){
						this.wordCard = {}
						this.setwordobj(newVal)
					}
			    },
			},
		},
		data() {
			return {
				isLinstening: false, //是否正在播放音频
				wordTpye: [],//释义 n.动词
				syllables:[],//音节
				exchange:{},//词态
				sentence:[],//例句
				phrase:[],//词组搭配
				synonym:[],//近义词
				exchangetext:{
					word_pl:"复数",
					word_third:"三单",
					word_past:"过去式",
					word_done:"过去分词",
					word_ing:"现在分词",
					word_adj:"形容词",
					word_er:"比较级",
					word_est:"最高级",
					word_noun:"名词",
				},//词态
				isShowAll: true,
				levels: ["例句", "近义词", "词组搭配"],
				levelChecked: 0,
				wordGroup: [],//词组搭配
				uid:null,//单词id
				wordCard:{},
				colorlist:["#0EEC9C","#FFBC0F","#0EB7EC","#AA56FF","#FF434E","#FF439D","#0E82EC"],
			};
		},
		methods: {
			//播放音频
			playAudio(src) {
				var _this = this
				try{
					_this.$refs.commonaudio.src = src;
					_this.$nextTick(res => {
					    _this.$refs.commonaudio.load()
					    _this.$refs.commonaudio.play()
					})
				}catch(e){
					//TODO handle the exception
				}
			},
			//详情
			getdetail(){
				var _this = this
				var params = {
					id: this.uid
				}
				this.$http.post('app/word/words/detail', params).then(function(res) {
					if (res.code == 200) {
						_this.setwordobj(res.data)
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})			
			},
			//加载数据数据显示
			setwordobj(wordCard){
				if(wordCard){
					try{
						this.wordTpye =  wordCard.definitionList || JSON.parse(wordCard.definition)
					}catch(e){
						this.wordTpye = []
					}
					try{
						this.exchange = JSON.parse(wordCard.exchange)
					}catch(e){
						this.exchange = ''
					}
					try{
						this.syllables = wordCard.syllables.split('#')
					}catch(e){
						this.syllables = []
					}
					//例句
					try{
						this.sentence = JSON.parse(wordCard.sentence)
					}catch(e){
						this.sentence = []
					}
					this.sentence.forEach((item, index) => {
						item["name"] = this.join(item.en,wordCard.word)
					});
					//词组搭配
					try{
						this.phrase = JSON.parse(wordCard.phrase)
					}catch(e){
						this.phrase = []
					}
					//近义词
					try{
						this.synonym = JSON.parse(wordCard.synonym)
					}catch(e){
						this.synonym = []
					}
					this.wordCard = wordCard
					console.log(this.wordCard)
				}else{
					this.$message.error("单词卡不存在，请返回重试")
				}
			},
			// 拼接
			join(str,key){
				var reg = new RegExp((`(${key})`), "gm");
				//var replace = `<span class="sentence-wrod-style">I need some children's <span class="sentence-wrod-style-block"><div class="sentence-key-wrod-style"></div><span class="sentence-key-wrod-text">crayons</span></span>.</span>`
				var replace = '<span class="sentence-wrod-style-block"><span class="sentence-key-wrod-style"></span><span class="sentence-key-wrod-text" style="font-weight:bold;">$1</span></span>';
				return str.replace(reg, replace);
			},
			changeLevel(index) {
				this.levelChecked = index;
			},
		},
	};
</script>

<style>
	.sentence-wrod-style {
		font-size: 16px;
		color: #000;
		margin-top: 8px;
	}

	.sentence-key-wrod-text {
		position: relative;
		z-index: 9;
	}

	.sentence-wrod-style-block {
		position: relative;
	}

	.sentence-key-wrod-style {
		position: absolute;
		width: 88%;
		height: 10px;
		background: #fdf0cf;
		left: 3px;
		bottom: 1px;
		border-radius: 2px;
	}
</style>

<style lang="scss" scoped>
	.this-block {
		width: 680px;
		border-radius: 20px;
		padding: 28px;
		position: relative;
		box-sizing: border-box;
		.word-pic {
			position: absolute;
			width: 165px;
			height: 100px;
			border-radius: 6px;
			right: 32px;
			top: 32px;
		}

		.flex-col {
			display: flex;
			width: 400px;

			.master-left {
				display: flex;
				flex-direction: column;
			}

			.master {
				color: #222;
				font-size: 34px;
				font-weight: bold;
				position: relative;
				display: flex;
				word-break: break-all;

				span {
					position: relative;
					z-index: 8;
				}

				.box-showdow {
					position: absolute;
					width: 100%;
					height: 10px;
					background: #fdf0cf;
					left: 3px;
					bottom: 2px;
					border-radius: 4px;
				}
			}

			.linsten-block {
				margin-top: 7px;
				display: flex;
				align-items: center;

				span {
					font-size: 16px;
					margin-left: 12px;
					color: #666;
				}
			}

			.linsten-btn {
				border-radius: 20px;
				border: 1px solid #e0e0e0;
				background: #fff;
				box-shadow: 0px 4px 5.2px 0px rgba(0, 0, 0, 0.07);
				height: 24px;
				width: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				&:hover {
					transform: scale(1.05);
				}

				img {
					width: 16px;
					height: 16px;
				}
			}
		}

		.say-list {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin-top: 4px;
			font-size: 13px;
			.say-item {
				padding: 2px 6px;
				color: #fff;
				border-radius: 4px;
				margin-top: 6px;
			}

			.line {
				color: #999999;
				margin: 0 4px;
			}
		}

		.desc-item {
			display: flex;
			flex-wrap: wrap;
			margin-top: 16px;
			line-height: 18px;
			font-size: 16px;
			.desc2{
				margin-left: 4px;
			}
			span:last-child {
				color: #222222;
				margin-right: 5px;
			}
		}

		.other-desc {
			margin-top: 20px;
			border-top: 1px dashed #dbdbdb;
			.other-desc-item {
				display: flex;
				margin-top: 16px;
				line-height: 22px;
				.desc2{
					margin-left: 4px;
					font-size: 14px;
					margin-left: 10px;
				}
				span:first-child {
					font-size: 14px;
					color: #999999;
					flex-shrink: 0;
					min-width: 40px;
				}
			}
		}

		.filter-list {
			display: flex;
			flex-wrap: nowrap;
			border-top: 1px dashed #dbdbdb;
			margin-top: 20px;

			.checked-item {
				font-weight: bold;
				color: #5c3704 !important;
			}

			.filter-item {
				padding: 10px 4px 4px 0;
				height: 40px;
				flex-shrink: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				line-height: 40px;
				font-size: 16px;
				color: #666666;
				position: relative;
				margin-right: 28px;
				cursor: pointer;

				&:hover {
					color: rgba(92, 55, 4, 0.5);
				}
			}

			.line {
				height: 2px;
				width: 16px;
				position: absolute;
				bottom: 1px;
				left: 50%;
				transform: translateX(-50%);
				border-radius: 2px;
				background: #51d9a5;
			}
		}

		.sentence-item {
			display: flex;
			flex-direction: column;
			background: #ffffff;
			border-radius: 8px;
			font-size: 15px;
			color: #000;
			padding: 8px 16px;
			margin-bottom: 8px;
			span {
				margin-top: 8px;
				&:first-child {
					margin-top: 0;
					position: relative;
				}
			}
		}

		.any-block-style {
			background: #f5f2f2;
			border-radius: 16px;
			font-size: 16px;
			color: #000;
			padding: 12px;
			margin-top: 12px;
			min-height: 220px;
			.synonymbox {
				margin-bottom: 12px;
				&:last-child {
					margin-bottom: 0;
				}
			}
			.text2box{
				margin-top: 8px;
			}
			.text2{
				font-size: 15px;
				margin-right: 10px;
				color: #51d9a5;
			}
		}
	}
</style>